import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Layout, SEO } from 'components'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: '0 auto',
    paddingTop: '50px',
    textAlign: 'justify',
    width: '1050px',
    [theme.breakpoints.up('xs')]: {
      paddingTop: '20px',
      width: '280px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '20px',
      width: '320px',
    },
    '@media (min-width: 768px)': {
      paddingTop: '20px',
      width: '690px',
    },
    '@media (min-width: 1200px) ': {
      paddingTop: '20px',
      width: '1050px',
    },
  },
  subTittle: {
    color: theme.palette.text.gray,
    fontWeight: 'bold',
    marginBottom: '4px',
    marginTop: '4px',
    opacity: '0.8',
    fontSize: '1.25rem',
  },
  tittle: {
    color: theme.palette.text.gray,
    fontWeight: 'bold',
    marginBottom: '4px',
    marginTop: '4px',
    fontSize: '1.5rem',
  },
}))

const TermsOfServicePage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="About us" />
      <div className={classes.mainContainer}>
        <Typography className={classes.tittle}>User Agreement </Typography>
        <Typography className={classes.subTittle}>
          1. Accepting These Terms
        </Typography>
        <Typography component={'span'}>
          <p>
            This document and the other documents that we reference below make
            up our house rules, or what we officially call our Terms of Use (the
            “Terms” for short).
          </p>
          The Terms are a legally binding contract between you and SMTHY. If you
          live in North America or South America, the contract is between you
          and SMTHY Enterprises, LLC. <br />
          We’ll just refer to SMTHY Enterprises, LLC. and all of its
          subsidiaries collectively as “SMTHY.”
          <p>
            Please note in Section 10, “Disputes with SMTHY”, it contains an
            arbitration clause and class action waiver. By agreeing to the
            Terms, you agree to resolve all disputes through binding individual
            arbitration, meaning that you waive any right to have those disputes
            decided by a judge or jury, and that you waive your right to
            participate in class actions, class arbitrations, or representative
            actions.
          </p>
          <p>
            This contract sets out your rights and responsibilities when you use
            SMTHY.app, Pattern by SMTHY, our mobile apps, and the other services
            provided by SMTHY (we’ll refer to all of these collectively as our
            “Services”), so please read it carefully. By using any of our
            Services (even just browsing one of our websites), you’re agreeing
            to the Terms. If you don’t agree with the Terms, you may not use our
            Services. Agree with us? Great, read on!
          </p>
        </Typography>
        <Typography className={classes.subTittle}>2. Your Privacy</Typography>
        <Typography component={'span'}>
          <p>
            We know your personal information is important to you, so it’s
            important to us. Our Privacy Policy details how your information is
            used when you use our Services. By using our Services, you're also
            agreeing that we can process your information in the ways set out in
            the Privacy Policy (as seen below).
          </p>
          Both SMTHY and makers process members’ personal information (for
          example, consumer name, email address, and shipping address) and are
          therefore considered separate and independent data controllers of
          buyers’ personal information under EU law. That means that each party
          is responsible for the personal information it processes in providing
          the Services. For example, if a maker accidentally discloses a
          consumer’s name and email address when fulfilling another consumers’s
          order, the seller, not SMTHY, will be responsible for that
          unauthorized disclosure.
          <p>
            If, however, SMTHY and makers are found to be joint data controllers
            of consumers’ personal information, and if SMTHY is sued, fined, or
            otherwise incurs expenses because of something that you did as a
            joint data controller of consumer personal information, you agree to
            indemnify SMTHY for the expenses it occurs in connection with your
            processing of buyer personal information.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          3. Your Account with SMTHY
        </Typography>
        <Typography component={'span'}>
          <p>
            You’ll need to create an account with SMTHY to use some of our
            Services. Here are a few rules about accounts with SMTHY:
          </p>
          <ol type="A">
            <li>
              You must be 18 years or older to use our Services. Minors under 18
              and at least 13 years of age are only permitted to use our
              Services through an account owned by a parent or legal guardian
              with their appropriate permission and under their direct
              supervision. Children under 13 years are not permitted to use
              SMTHY or the Services. You are responsible for any and all account
              activity conducted by a minor on your account, and there may be
              commercial products or services available that you may want to
              consider to limit a minor's access to material online.{' '}
            </li>
            <li>
              Be honest with us. Provide accurate information about yourself.
              It’s prohibited to use false information or impersonate another
              person or company through your account.
            </li>
            <li>
              Choose an appropriate name. If you decide to not have your full
              name serve as the name associated with your account, you may not
              use language that is offensive, vulgar, infringes someone’s
              intellectual property rights, or otherwise violates the Terms.
            </li>
            <li>
              You're responsible for your account. You’re solely responsible for
              any activity on your account. If you’re sharing an account with
              other people, then the person whose financial information is on
              the account will ultimately be responsible for all activity. If
              you’re registering as a business entity, you personally guarantee
              that you have the authority to agree to the Terms on behalf of the
              business. Also, your accounts are not transferable.
            </li>
            <li>
              Protect your password. As we mentioned above, you’re solely
              responsible for any activity on your account, so it’s important to
              keep your account password secure.
            </li>
            <li>
              Let's be clear about our relationship. These Terms don't create
              any agency, partnership, joint venture, employment, or franchisee
              relationship between you and SMTHY.
            </li>
          </ol>
        </Typography>
        <Typography className={classes.subTittle}>4. Your Content</Typography>
        <Typography component={'span'}>
          <p>
            Content that you post using our Services is your content (so let’s
            refer to it as “Your Content”). We don’t make any claim to it, which
            includes anything you post using our Services (like shop names,
            profile pictures, listing photos, listing descriptions, reviews,
            comments, videos, usernames, etc.).
          </p>
          <ol type="A">
            <li>
              Y Responsibility for Your Content. You understand that you are
              solely responsible for Your Content. You represent that you have
              all necessary rights to Your Content and that you’re not
              infringing or violating any third party’s rights by posting it.
            </li>
            <li>
              Permission to Use Your Content. By posting Your Content through
              our Services, you grant SMTHY a license to use it. We don’t claim
              any ownership to Your Content, but we have your permission to use
              it to help SMTHY function and grow. That way, we won’t infringe
              any rights you have in Your Content and we can help promote it. .
              For example, you acknowledge and agree SMTHY may offer you or
              SMTHY buyers promotions on the Site, from time to time, that may
              relate to your listings.
            </li>
            <li>
              Rights You Grant SMTHY. (Here’s the legalese version of the last
              section). By posting Your Content, you grant SMTHY a
              non-exclusive, worldwide, royalty-free, irrevocable,
              sub-licensable, perpetual license to use, display, edit, modify,
              reproduce, distribute, store, and prepare derivative works of Your
              Content. This allows us to provide the Services and to promote
              SMTHY, your SMTHY shop, or the Services in general, in any formats
              and through any channels, including across any SMTHY Services, our
              partners, or third-party website or advertising medium. You agree
              not to assert any moral rights or rights of publicity against us
              for using Your Content. You also recognize our legitimate interest
              in using it, in accordance with the scope of this license, to the
              extent Your Content contains any personal information.
              <br />
              That sounds like a lot, but it’s necessary for us to keep SMTHY
              going. Consider these examples: if you upload a photo or video of
              a listing on your SMTHY shop, we have permission to display it to
              buyers, and we can resize or enhance it so it looks good to a
              buyer using our mobile app; if you post a description in English,
              we can translate it into French so a buyer in Paris can learn the
              story behind your item; and if you post a beautiful photo or video
              of your latest handmade necklace, we can feature it– often along
              with your shop name and shop picture– on our homepage, in one of
              our blogs or even on a billboard to help promote your business and
              SMTHY’s.
            </li>
            <li>
              Reporting Unauthorized Content. SMTHY has great respect for
              intellectual property rights, and is committed to following
              appropriate legal procedures to remove infringing content from the
              Services. If content that you own or have rights to has been
              posted to the Services without your permission and you want it
              removed, please contact support@smthy.app. If Your Content is
              alleged to infringe another person’s intellectual property, we
              will take appropriate action, such as disabling it if we receive
              proper notice or terminating your account if you are found to be a
              repeat infringer. We’ll notify you if any of that happens.
            </li>
            <li>
              Inappropriate, False, or Misleading Content. This should be common
              sense, but there are certain types of content we don’t want posted
              on SMTHY’s Services (for legal reasons or otherwise). You agree
              that you will not post any content that is abusive, threatening,
              defamatory, obscene, vulgar, or otherwise offensive or in
              violation of our Terms. You also agree not to post any content
              that is false and misleading or uses the Services in a manner that
              is fraudulent or deceptive.
            </li>
          </ol>
        </Typography>
        <Typography className={classes.subTittle}>
          5. Your Use of Our Services
        </Typography>
        <Typography component={'span'}>
          <p>
            License to Use Our Services. We grant you a limited, non-exclusive,
            non-transferable, and revocable license to use our Services—subject
            to the Terms and the following restrictions in particular:
          </p>
          <ol type="A">
            <li>
              Don’t Use Our Services to Break the Law. You agree that you will
              not violate any laws in connection with your use of the Services.
              This includes any local, state, federal, and international laws
              that may apply to you. For example, it’s your responsibility to
              obtain any permits or licenses that your shop requires, and to
              meet applicable legal requirements in applicable jurisdiction(s).
              This includes the sale and delivery of your items, such as age
              verification upon delivery, where required by law. You may not
              sell anything that violates any laws; you must comply with our
              Sanctions Policy, and you may not engage in fraud (including false
              claims or infringement notices), theft, anti-competitive conduct,
              threatening conduct, or any other unlawful acts or crimes against
              SMTHY, another SMTHY user, or a third party.
            </li>
            <li>
              Pay Your Bills. You are responsible for paying all fees that you
              owe to SMTHY. Except as set forth below, you are also solely
              responsible for collecting and/or paying any applicable taxes for
              any purchases or sales you make through our Services. For digital
              items sold to buyers in Australia, Belarus, Chile, the EU,
              Iceland, India, Indonesia, Japan, Malaysia, Mexico, Moldova, New
              Zealand, Norway, Quebec (Canada), Russia, Saudi Arabia, Serbia,
              Singapore, South Africa, South Korea, Switzerland, Taiwan, Turkey,
              United Arab Emirates or the United Kingdom. SMTHY will help
              collect and remit the correct amount of value-added tax or VAT.
              Some countries may refer to VAT using other terms, e.g. Goods and
              Services Tax (GST), but we’ll just refer to VAT, GST, and any
              local sales taxes collectively as “VAT.” In addition, SMTHY will
              calculate, collect, and remit sales tax where applicable.
            </li>
            <li>
              Don’t Steal Our Stuff. You agree not to crawl, scrape, or spider
              any page of the Services or to reverse engineer or attempt to
              obtain the source code of the Services.
            </li>
            <li>
              Don’t Try to Harm Our Systems. You agree not to interfere with or
              try to disrupt our Services, for example by distributing a virus
              or other harmful computer code.
            </li>
            <li>
              IFollow Our Trademark Policy. The name "SMTHY" and the other SMTHY
              marks, phrases, logos, and designs that we use in connection with
              our Services (the SMTHY Trademarks), are trademarks, service
              marks, or trade dress of SMTHY in the US and other countries.
            </li>
            <li>
              Share Your Ideas. We love your suggestions and ideas! They can
              help us improve your experience and our Services. Any unsolicited
              ideas or other materials you submit to SMTHY (not including Your
              Content or items you sell through our Services) are considered
              non-confidential and non-proprietary to you. You grant us a
              non-exclusive, worldwide, royalty-free, irrevocable,
              sub-licensable, perpetual license to use and publish those ideas
              and materials for any purpose, without compensation to you.
            </li>
            <li>
              Talk to Us Online. From time to time, SMTHY will provide you with
              certain legal information in writing. By using our Services,
              you’re agreeing to our Electronic Communications Policy, which
              describes how we provide that information to you. It says that we
              can send you information electronically (such as by email) instead
              of mailing you paper copies (it’s better for the environment), and
              that your electronic agreement is the same as your signature on
              paper.
            </li>
          </ol>
        </Typography>

        <Typography className={classes.subTittle}>6. Termination</Typography>
        <Typography component={'span'}>
          <p>
            Termination By You. We'd hate to see you go, but you may terminate
            your account with SMTHY at any time from your account settings.
            Terminating your account will not affect the availability of some of
            Your Content that you posted through the Services prior to
            termination. Oh, and you’ll still have to pay any outstanding bills.
          </p>
          Termination By SMTHY. We may terminate or suspend your account (and
          any accounts SMTHY determines are related to your account) and your
          access to the Services should we have reason to believe you, your
          Content, or your use of the Services violate our Terms. If we do so,
          it’s important to understand that you don’t have a contractual or
          legal right to continue to use our Services, for example, to sell or
          buy on our websites or mobile apps. Generally, SMTHY will notify you
          that your account has been terminated or suspended, unless you’ve
          repeatedly violated our Terms or we have legal or regulatory reasons
          preventing us from notifying you.
          <p>
            If you or SMTHY terminate your account, you may lose any information
            associated with your account, including Your Content.
          </p>
          <p>
            We May Discontinue the Services. SMTHY reserves the right to change,
            suspend, or discontinue any of the Services at any time, for any
            reason, including those laid out in SMTHY’s policies under these
            Terms of Use. We will not be liable to you for the effect that any
            changes to the Services may have on you, including your income or
            your ability to generate revenue through the Services.
          </p>
          <p>
            Survival. The Terms will remain in effect even after your access to
            the Service is terminated, or your use of the Service ends.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          7. Warranties and Limitation of Liability (or the Things You Can’t Sue
          Us For)
        </Typography>
        <Typography component={'span'}>
          <p>
            Items You Purchase. You understand that SMTHY does not manufacture,
            store, or inspect any of the items sold through our Services. We
            provide the venue; the items in our marketplaces are produced,
            listed, and sold directly by independent sellers, so SMTHY cannot
            and does not make any warranties about their quality, safety, or
            even their legality. Any legal claim related to an item you purchase
            must be brought directly against the seller of the item. You release
            SMTHY from any claims related to items sold through our Services,
            including for defective items, misrepresentations by sellers, or
            items that caused physical injury (like product liability claims).
          </p>
          Content You Access. You may come across materials that you find
          offensive or inappropriate while using our Services. We make no
          representations concerning any content posted by users through the
          Services. SMTHY is not responsible for the accuracy, copyright
          compliance, legality, or decency of content posted by users that you
          accessed through the Services. You release us from all liability
          relating to that content.
          <p>
            People You Interact With. You can use the Services to interact with
            other individuals, either online or in person. However, you
            understand that we do not screen users of our Services, and you
            release us from all liability relating to your interactions with
            other users. Please be careful and exercise caution and good
            judgment in all interactions with others, especially if you are
            meeting someone in person.
          </p>
          <p>
            Third-Party Services. Our Services may contain links to third-party
            websites or services that we don’t own or control (for example,
            links to Facebook, Twitter, and Pinterest). You may also need to use
            a third party’s product or service in order to use some of our
            Services (like a compatible mobile device to use our mobile apps).
            When you access these third-party services, you do so at your own
            risk. The third parties may require you to accept their own terms of
            use. SMTHY is not a party to those agreements; they are solely
            between you and the third party.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          8. Indemnification (or What Happens If You Get Us Sued)
        </Typography>
        <Typography component={'span'}>
          <p>
            We hope this never happens, but if SMTHY gets sued because of
            something that you did, you agree to defend and indemnify us. That
            means you’ll defend SMTHY (including any of our employees) and hold
            us harmless from any legal claim or demand (including reasonable
            attorney’s fees) that arises from your actions, your use (or misuse)
            of our Services, your breach of the Terms, or you or your account’s
            infringement of someone else’s rights.
          </p>
          <p>
            We reserve the right to handle our legal defense however we see fit,
            even if you are indemnifying us, in which case you agree to
            cooperate with us so we can execute our strategy.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          9. Disputes with Other Users
        </Typography>
        <Typography component={'span'}>
          <p>
            If you find yourself in a dispute with another user of SMTHY’s
            Services or a third party, we encourage you to contact the other
            party and try to resolve the dispute amicably.
          </p>
          <p>
            Case System. Consumers and Makerss who are unable to resolve a
            dispute related to a transaction on our websites or mobile apps may
            participate in our case system. SMTHY will attempt to help you
            resolve disputes in good faith and based solely on our
            interpretation of our policies, in our sole discretion; we will not
            make judgments regarding legal issues or claims. SMTHY has no
            obligation to resolve any disputes.
          </p>
          <p>
            Release of SMTHY. You release SMTHY from any claims, demands, and
            damages arising out of disputes with other users or parties.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          10. Disputes with SMTHY
        </Typography>
        <Typography component={'span'}>
          <p>
            If you’re upset with us, let us know, and hopefully we can resolve
            your issue. But if we can’t, then these rules will govern any legal
            dispute involving our Services:
          </p>
          <ol type="A">
            <li>
              Governing Law. The Terms are governed by the laws of the State of
              New York, without regard to its conflict of laws rules, and the
              laws of the United States of America. These laws will apply no
              matter where in the world you live, but if you live outside of the
              United States, you may be entitled to the protection of the
              mandatory consumer protection provisions of your local consumer
              protection law.
            </li>
            <li>
              Arbitration. You and SMTHY agree that any dispute or claim arising
              from or relating to the Terms shall be finally settled by final
              and binding arbitration, using the English language, administered
              by the American Arbitration Association (the “AAA”) under its
              Consumer Arbitration Rules (the "AAA Rules") then in effect (those
              rules are deemed to be incorporated by reference into this
              section, and as of the date of these Terms you can find the AAA
              Rules here), unless otherwise required by law. **Arbitration,
              including threshold questions of arbitrability of the dispute,
              will be handled by a sole arbitrator in accordance with those
              rules. Judgment on the arbitration award may be entered in any
              court that has jurisdiction.
              <br />
              For EU sellers, if any dispute arises in connection with the
              Terms, the parties should first try to resolve the dispute through
              the complaints procedure. In addition, the dispute may be referred
              by either party to the Centre for Effective Dispute Resolution
              (“CEDR”) for mediation. The Parties agree to enter into mediation
              to settle a good faith dispute and will do so in accordance with
              the CEDR’s mediation procedures. Unless otherwise agreed between
              the parties within 14 days of notice of the dispute, the mediator
              will be nominated by CEDR. To initiate the mediation a party must
              give notice in writing to the other party to the dispute,
              referring the dispute to mediation. A copy of the referral should
              be sent to CEDR.
              <br />
              Any arbitration or mediation under the Terms will take place on an
              individual basis. You understand that by agreeing to the Terms,
              you and SMTHY are each waiving the right to trial by jury or to
              participate in a class action lawsuit. Class arbitrations shall
              only be available if requested by either party under its Class
              Action Arbitration Rules and approved by the arbitration entity.
              Notwithstanding the foregoing, each party shall have the right to
              bring an action in a court of proper jurisdiction for injunctive
              or other equitable or conservatory relief, pending a final
              decision by the arbitrator or mediator. You may instead assert
              your claim in “small claims” court, but only if your claim
              qualifies, your claim remains in such court, and your claim
              remains on an individual, non-representative, and non-class basis.
            </li>
            <li>
              Costs of Arbitration. Payment for any and all reasonable AAA
              filing, administrative, and arbitrator fees will be in accordance
              with the Consumer Arbitration Rules, and in the case of CEDR, its
              rules. If the value of your claim does not exceed $10,000 USD,
              SMTHY will pay for the reasonable filing, administrative, and
              arbitrator fees associated with the arbitration, unless the
              arbitrator finds that either the substance of your claim or the
              relief sought was frivolous or brought for an improper purpose.
              For mediation through CEDR, the parties will pay their share of
              mediation costs, and under certain conditions such fees may be
              refundable to you, depending on the outcome of the mediation.
            </li>
            <li>
              Government Exception. If you are a government agent or entity in
              the United States using the Services in your official capacity,
              and you are legally unable to agree to the clauses in this
              section, then those clauses do not apply to you. In that case, the
              Terms and any action related to the Terms will be governed by the
              laws of the United States (without reference to conflict of laws)
              and, in the absence of federal law and to the extent permitted
              under federal law, the laws of the State of Delaware.
            </li>
            <li>
              Modifications. If we make any changes to this “Disputes with
              SMTHY” section after the date you last accepted the Terms, those
              changes will not apply to any claims filed in a legal proceeding
              against SMTHY prior to the date the changes became effective.
              SMTHY will notify you of substantive changes to the “Disputes with
              SMTHY” section at least 30 days prior to the date the change will
              become effective. If you do not agree to the modified terms, you
              may send SMTHY a written notification (including email) or close
              your account within those 30 days. By rejecting a modified term or
              permanently closing your account, you agree to arbitrate any
              disputes between you and SMTHY in accordance with the provisions
              of this “Disputes with SMTHY” section as of the date you last
              accepted the Terms, including any changes made prior to your
              rejection. If you reopen your closed account or create a new
              account, you agree to be bound by the current version of the
              Terms.
            </li>
          </ol>
        </Typography>
        <Typography className={classes.subTittle}>
          11. Changes to the Terms
        </Typography>
        <Typography component={'span'}>
          <p>
            We may update these Terms from time to time. If we believe that the
            changes are material, we’ll definitely let you know by posting the
            changes through the Services and/or sending you an email or message
            about the changes. That way you can decide whether you want to
            continue using the Services. Changes will be effective upon the
            posting of the changes unless otherwise specified. You are
            responsible for reviewing and becoming familiar with any changes.
            Your use of the Services following the changes constitutes your
            acceptance of the updated Terms.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          12. Some Finer Legal Points
        </Typography>
        <Typography component={'span'}>
          <p>
            The Terms, including all of the policies that make up the Terms,
            supersede any other agreement between you and SMTHY regarding the
            Services. If any part of the Terms is found to be unenforceable,
            that part will be limited to the minimum extent necessary so that
            the Terms will otherwise remain in full force and effect. Our
            failure to enforce any part of the Terms is not a waiver of our
            right to later enforce that or any other part of the Terms. We may
            assign any of our rights and obligations under the Terms.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          13. Contact Information
        </Typography>
        <Typography component={'span'}>
          <p>
            If you have any questions about the Terms, please email us at
            support@SMTHY.app.
          </p>
          <p>
            *In some countries you may have additional rights and/or the
            preceding may not apply to you.
          </p>
        </Typography>
        <Typography className={classes.tittle}>PRIVACY POLICY</Typography>
        <Typography className={classes.subTittle}>Overview</Typography>
        <Typography component={'span'}>
          <p>
            Your privacy is very important to all of us at SMTHY. We have
            established this privacy policy (“Privacy Policy”) to explain to you
            how your personal information is protected, collected and used. It
            also describes your choices regarding use, access and correction of
            your personal information. Personal information is information about
            you that is personally identifiable such as your name, address,
            phone number, and email address that is not otherwise publicly
            available.
          </p>
          The privacy practices of this statement apply to our services
          available under the domain and subdomains of www.SMTHY.app (the
          "Site") owned and operated by SMTHY Enterprises, LLC (“SMTHY”, “we”,
          “our”, “us”).
          <p>
            SMTHY Enterprises, LLC is the controller of your Personal Data and
            responsible for the collection, processing and disclosure of your
            Personal Data as described in this Privacy Policy unless expressly
            specified otherwise.
          </p>
          <p>
            By visiting this website, you agree to be bound by the terms and
            conditions of this Privacy Policy and expressly consent to our use
            and disclosure of your personal information in accordance with this
            Privacy Policy. If you do not agree, please do not use or access our
            site.
          </p>
          <p>
            This Privacy Policy is incorporated into and subject to the terms of
            the SMTHY User Agreement.
          </p>
          <p>
            We reserve the right to modify this Privacy Policy at any time, so
            please review it frequently.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          Personal Data we collect
        </Typography>
        <Typography component={'span'}>
          <p>
            In general, you can browse the Site without providing us with any of
            personal information about yourself. You don’t have to create an
            account to use some of the SMTHY services, such as viewing public
            SMTHY profiles or public listings. However even when browsing, SMTHY
            may receive some personal information from you, like the device
            you’re using and your IP address.
          </p>
          <p>
            In order to use most services or features on the Site, you must
            first complete the registration form and create a username and
            password. During the registration process, you are required to give
            contact information such as name, address, phone number, and email
            address. Note, once you agree to provide us with personal
            information, you are no longer anonymous to us.
          </p>
          <p>
            If you choose to use our services, we may require you to provide
            contact and identity information, billing information, and other
            personal information as indicated on the forms throughout the Site.
            Under some circumstances we may require some additional financial
            information. Where possible, we indicate which fields are required
            and which are optional.
          </p>
          <p>
            You always have the option to not provide information by choosing
            not to use a particular service or feature.
          </p>
          <p>
            If you send us personal correspondence, such as emails or letters,
            or if other users or third parties send us correspondence about your
            activities or postings on the Site, we may collect such information
            into a file specific to you.
          </p>
          <p>
            From time-to-time we may provide you the opportunity to participate
            in contests or surveys on the Site. If you choose to participate, we
            will request certain personal information, including your email
            address, from you. Participation in these surveys or contests is
            completely voluntary and you therefore have a choice whether or not
            to disclose this information. The requested information typically
            includes contact information and demographic information. We will
            not share the personal information you provide through a contest or
            survey with other third parties unless we give you prior notice and
            choice.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>Log Files</Typography>
        <Typography component={'span'}>
          <p>
            As is true of most Web sites, we gather certain information
            automatically and store it in log files. This information includes
            internet protocol (IP) addresses, browser type, internet service
            provider (ISP), referring/exit pages, operating system, date/time
            stamp, and clickstream data. We use this information to analyze
            trends, to administer the site, to track users’ movements around the
            site and to gather demographic information about our user base as a
            whole.
          </p>
          <p>
            We track trends in users’ usage and volume statistics to create a
            more efficient and usable site, and to determine areas of the site
            that could be improved to enhance the user experience.
          </p>
          <p>
            Log files are used on the website, and in any link to the website
            from an email.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          Tracking Technologies
        </Typography>
        <Typography component={'span'}>
          <p>
            Technologies such as: cookies and similar technologies are used by
            SMTHY and our marketing partners, affiliates, and customer support
            and fraud prevention service providers. These technologies are used
            in analyzing trends, administering the site, tracking users’
            movements around the site and to gather demographic information
            about our user base as a whole. We may receive reports based on the
            use of these technologies by these companies on an individual as
            well as aggregated basis.
          </p>
          <p>
            We use cookies to remember user preferences and for authentication.
            Users can control the use of cookies at the individual browser
            level. If you reject cookies, you may still use our site, but your
            ability to use some features or areas of our site may be limited.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          How we use your personal data
        </Typography>
        <Typography component={'span'}>
          <p>
            We collect and use your data principally for the following purposes:
          </p>
          <ul>
            <li>
              Provide services and features that are most likely to meet your
              needs and facilitate the services you request
            </li>
            <li>
              Customize your experience and the content, products, and services
              offered to you
            </li>
            <li>
              Verify the accuracy of your name, address, and other information
            </li>
            <li>Bill you for your use of our services and collect fees owed</li>
            <li>
              Provide support for your transactions, products, and services you
              use
            </li>
            <li>Assist you to resolve disputes</li>
            <li>Comply with legal requirements</li>
            <li>
              Inform you about online and offline offers, products, services,
              and updates
            </li>
            <li>
              Provide targeted advertising, including the use of re-targeting
              technology
            </li>
            <li>Measure consumer interest in our products and services</li>
            <li>
              Detect and protect us against error, fraud, and other criminal
              activity and enforce our User Agreement
            </li>
            <li>
              Look across multiple users to identify problems or resolve
              disputes and identify users using multiple usernames or aliases
            </li>
            <li>
              Compare and review your personal information for errors,
              omissions, and accuracy
            </li>
            <li>
              Conduct internal research on our users’ demographics, interest,
              and behavior to better understand, protect, and serve you and our
              community
            </li>
            <li>
              Improve our marketing and promotional efforts, analyze site usage,
              improve our content and product offerings, and customize the
              Site's content, layout, and services
            </li>
            <li>
              Collect personal information on behalf of our payment processors
              in order to meet compliance obligations, such as Know Your
              Customer.
            </li>
          </ul>
        </Typography>
        <Typography className={classes.subTittle}>
          Information Sharing and Disclosure
        </Typography>
        <Typography component={'span'}>
          <p>
            As a matter of policy, we do not sell, rent or share any of your
            personal information, including your email address, to third parties
            for their marketing purposes without your explicit consent. The
            profile you create on the Site will be publicly accessible unless
            otherwise indicated. You may change the privacy settings of your
            profile through your account portal. However, the following
            describes some of the ways that your personal information may be
            disclosed in the normal scope of business to provide our services.
          </p>
          <p>
            <strong>SMTHY Community: </strong> Your SMTHY username is displayed
            throughout the Site and is therefore available to the public. All of
            your activities on the Site will be identifiable to your username.
            Other people can see your quotes, shipments you have listed, your
            feedback rating and associated comments, your postings on the site,
            and so on. Therefore, if you associate your name with your username,
            the people you have revealed your name to will be able to personally
            identify your SMTHY activities. If you are involved in a
            transaction, we have features that allow parties to the transaction
            to view your email address and obtain your contact information to
            help complete the transaction. Further, we may send out notices to
            you or other community members regarding suspicious activity or
            policy violations on the site. Additionally, we may share your
            written correspondence, including emails to the other party to your
            transaction as part of the dispute resolutions process. To request
            removal of your personal information from the SMTHY community,
            contact us at our contact information found below. In some cases, we
            may not be able to remove your personal information, in which case
            we will let you know if we are unable to do so and why.
          </p>
          <p>
            <strong>External Service Providers:</strong>There may be separate
            services offered by third parties that we refer to as external
            service providers that may be complementary to your use of the site.
            If you choose to use these separate services, disclose information
            to the external service providers, and/or grant them permission to
            collect information about you, then their use of your information is
            governed by their privacy policy. We do not disclose your personal
            information, including your email address, to external service
            providers unless you provide your explicit consent. With your
            consent we may provide some of your personal information to the
            external service provider offering such services, for your
            convenience. To prevent our disclosure of your personal information
            to an external service provider, you can decline such consent or
            simply not use their services. Because we do not control the privacy
            practices of these third parties, you should evaluate their
            practices before deciding to use their services.
          </p>
          <p>
            <strong>Internal Service Providers for Our Operations:</strong> We
            may use third parties that we refer to as internal service providers
            to facilitate or outsource one or more aspects of the business
            (sometimes utilizing framing techniques), product and service
            operations that we provide to you and therefore we may provide some
            of your personal information, including your email address, directly
            to these internal service providers. Any sensitive information that
            is transmitted to internal service providers involved in any payment
            processing functions will be encrypted or tokenized. These internal
            service providers' are subject to confidentiality agreements with us
            and other legal restrictions that prohibit their use of the
            information we provide them for any other purpose except to
            facilitate the specific outsourced SMTHY related operation, unless
            you have explicitly agreed or given your prior permission to them
            for additional uses. If you provide additional information to an
            internal service provider directly, then their use of your personal
            information is governed by their applicable privacy policy.
          </p>
          <p>
            <strong>Legal Requests: </strong>SMTHY cooperates with law
            enforcement inquiries, as well as other third parties to enforce
            laws, such as: intellectual property rights, fraud and other rights,
            to help protect you and the SMTHY community from bad actors.
            Therefore, in response to a verified request by law enforcement or
            other government officials relating to a criminal investigation or
            alleged illegal activity, we can (and you authorize us to) disclose
            your name, city, state, telephone number, email address, user
            history, fraud complaints, and quoting and listing history without a
            subpoena. Without limiting the above, in an effort to respect your
            privacy and our ability to keep the community free from bad actors,
            we will not otherwise disclose your personal information to law
            enforcement or other government officials without a subpoena, court
            order or substantially similar legal procedure, except when we
            believe in good faith that the disclosure of information is
            necessary to: prevent imminent physical harm or financial loss; or
            report suspected illegal activity.
            <br />
            Due to the existing regulatory environment, we cannot ensure that
            all of your private communications and other personal information
            will never be disclosed in ways not otherwise described in this
            Privacy Policy. By way of example (without limiting the foregoing),
            we may be forced to disclose personal information, including your
            email address, to the government or third parties under certain
            circumstances, third parties may unlawfully intercept or access
            transmissions or private communications, or users may abuse or
            misuse your personal information that they collect from the Site.
            Therefore, although we use industry standard practices to protect
            your privacy, we do not promise, and you should not expect, that
            your personal information or private communications will always
            remain private. See “Security Section” below.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          Communications from the Site
        </Typography>
        <Typography component={'span'}>
          <p>
            We will occasionally send you information on products, services,
            special deals, and promotions, as well as a newsletter. You are
            signed up for these emails at registration and you may unsubscribe
            at any time by going to your account tab and opting out of the
            emails, or by following the directions to unsubscribe in the email.
          </p>
          <p>
            Out of respect for your privacy, we provide you a way to unsubscribe
            from each of these communications when we ask for it. If you no
            longer wish to receive our newsletter and promotional
            communications, you may opt-out of receiving them by following the
            instructions included in each newsletter or communication or by
            emailing us at support@SMTHY.app.
          </p>
          <p>
            You will be notified when your personal information is collected by
            any third party that is not our agent/service provider, so you can
            make an informed choice as to whether or not to share your
            information with that party.
          </p>
          <p>
            We will send you strictly service-related announcements on rare
            occasions when it is necessary to do so. For instance, if our
            service is temporarily suspended for maintenance, we might send you
            an email. Generally, you may not opt-out of these communications,
            which are not promotional in nature. If you do not wish to receive
            them, you have the option to deactivate your account.
          </p>
          <p>
            Based upon the personal information you provide us, we will send you
            a welcoming email to verify your email address, username and
            password. We will also communicate with you in response to your
            inquiries, to provide the services you request, and to manage your
            account. Email is the primary means of communication between members
            doing transactions on the site and you must be able to receive this
            information to use SMTHY properly.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          Links to Other Sites
        </Typography>
        <Typography component={'span'}>
          <p>
            This Web site contains links to other sites that are not owned or
            controlled by SMTHY. Please be aware that we are not responsible for
            the privacy practices of such other sites. We encourage you to be
            aware when you leave our site and to read the privacy policies of
            each and every Web site that collects personal information. This
            privacy policy applies only to information collected by this Web
            site.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>Testimonials</Typography>
        <Typography component={'span'}>
          <p>
            We display personal testimonials of satisfied customers on our site
            in addition to other endorsements. We may post your testimonial
            along with your first name and last initial. If you wish to update
            or delete your testimonial, you can contact us at support@SMTHY.app.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          Access to Personal Information
        </Typography>
        <Typography component={'span'}>
          <p>
            Upon request we will provide you with information about whether we
            hold, or process on behalf of a third party, any of your personal
            information. You may request this information by contacting
            support@SMTHY.app. We will respond to your request within 30 days.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>Security</Typography>
        <Typography component={'span'}>
          <p>
            The security of your personal information is important to us. When
            you enter sensitive information (such as credit card number) on our
            registration or order forms, we encrypt that information using
            secure socket layer technology (SSL).
          </p>
          <p>
            We follow generally accepted industry standards to protect the
            personal information, including your email address, submitted to us,
            both during transmission and once we receive it. No method of
            transmission over the Internet, or method of electronic storage, is
            100% secure, however. Therefore, while we strive to use commercially
            acceptable means to protect your personal information, we cannot
            guarantee its absolute security.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          International Privacy Laws
        </Typography>
        <Typography component={'span'}>
          <p>
            If you are visiting the Site outside of the United States, please be
            aware that you are sending information to the United States where
            our servers are located. That information may then be transferred
            within the United States or back out of the United States to other
            countries outside of your country of residence. These countries
            (including the United States) may not have data protection laws as
            comprehensive or protective as those in your country of residence;
            however, our collection, storage, and protection of your Personal
            Information will be governed by this Privacy Policy.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          Changes in this Privacy Policy
        </Typography>
        <Typography component={'span'}>
          <p>
            If we decide to change our privacy policy, we will post those
            changes to this privacy policy on our website and other places we
            deem appropriate so that you are aware of what information we
            collect, how we use it, and under what circumstances, if any, we
            disclose it.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>Contact Us</Typography>
        <Typography component={'span'}>
          <p>
            <i>
              If you have any questions or suggestions regarding our privacy
              policy or to exercise your rights regarding your personal
              information, please contact us at support@SMTHY.app.{' '}
            </i>
          </p>
        </Typography>
        <Typography className={classes.tittle}>
          Cookies &amp; Similar Technologies Policy
        </Typography>
        <Typography component={'span'}>
          <p>
            We think it's important for you to understand how cookies and other
            similar technologies are used by SMTHY and our partners. These
            technologies help SMTHY function, allow us to understand how you use
            the Services, and have a number of purposes (beyond just
            advertising) that you can read about in this policy.
          </p>
          <p>
            In this policy, we'll refer to SMTHY.app as the "Site" and SMTHY's
            mobile applications as the "Apps." We'll refer to the Site, the
            Apps, Pattern by SMTHY, SMTHY Payments, and our other services
            collectively as the "Services." We’ll refer to cookies and similar
            technologies collectively as “Cookie Technologies.”
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          1. Types of Cookie Technologies
        </Typography>
        <Typography component={'span'}>
          <p>
            <strong>Cookies</strong>
          </p>
          <p>
            Cookies are small data files sent from a server to your web browser.
            They are stored in your browser’s cache and allow a website or a
            third party to recognize your browser. There are three primary types
            of cookies:
          </p>
          <ul>
            <li>
              <i>Session cookies</i> are specific to a particular visit and
              carry information as you view different pages so you don’t have to
              re-enter information every time you change pages or attempt to
              checkout. Session cookies expire and delete themselves
              automatically in a short period of time like after you leave the
              Site or when you close your web browser.
            </li>
            <li>
              <i>Persistent cookies</i> remember certain information about your
              preferences for viewing the site, and allow SMTHY to recognize you
              each time you return. Persistent cookies are stored on your
              browser cache or mobile device until you choose to delete them,
              and otherwise typically delete themselves at expiration.
            </li>
            <li>
              <i>Third-party cookies</i> are placed by someone other than SMTHY,
              and may gather browsing activity across multiple websites and
              across multiple sessions. They are usually a type of persistent
              cookie and are stored until you delete them or they expire based
              on the time period set in each third-party cookie.
            </li>
          </ul>
          <p>
            Cookies store data about your use, but they are helpful because they
            allow us to help SMTHY function and customize your experience. You
            can configure your desktop or mobile browser's settings to reflect
            your preference to accept or reject cookies, including how to handle
            third-party cookies.
          </p>
          <p>
            <strong>Other Technologies</strong>
          </p>
          <p>
            In addition to cookies, there are other similar technologies used by
            SMTHY and elsewhere on the web or in mobile ecosystems.
          </p>
          <ul>
            <li>
              <i>Web beacons: </i>These are tiny graphics (sometimes called
              “clear GIFs” or “web pixels”) with a unique identifier that are
              used to understand browsing activity. In contrast to cookies,
              which are stored on a user's computer hard drive, web beacons are
              rendered invisibly on web pages when you open a page.
            </li>
            <li>
              <i>Social widgets: </i>These are buttons or icons provided by
              third-party social media providers that allow you to interact with
              those social media services when you view a web page or a mobile
              app screen. These social widgets may collect browsing data, which
              may be received by the third party that provided the widget, and
              are controlled by the third parties.
            </li>
            <li>
              <i>UTM codes: </i>These are strings that can appear in a URL (the
              “Uniform Resource Locator,” which is typically the http or https
              address entered to go to a web page) when a user moves from one
              web page or website to another, where the string can represent
              information about browsing, such as which advertisement, page, or
              publisher sent the user to the receiving website.
            </li>
            <li>
              <i>Application SDKs: </i>These are mobile application third-party
              software development kits that are embedded in the Apps (and are
              used in many mobile applications). These app SDKs permit the
              collection of information about the app itself, activity in the
              app, and the device the application is running on.
            </li>
            <li>
              <i>Local Storage Objects: </i>These are sets of data that can be
              stored on your browser by a site or app. They can be used to
              maintain preferences, a history of usage, or even the state or
              settings of a site or an app.
            </li>
            <li>
              <i>Internet of Things identifiers: </i>Like mobile identifiers,
              internet-connected devices such as voice activated assistants or
              smart TVs may send identifiers and other data analogous to web
              browsers or mobile SDKs.
            </li>
          </ul>
        </Typography>
        <Typography className={classes.subTittle}>2. Purposes</Typography>
        <Typography component={'span'}>
          <p>
            SMTHY uses Cookie Technologies to recognize your logged-in state on
            SMTHY, to understand what purchases members and visitors are
            interested in, to make SMTHY’s Sites function for you, and to help
            your browsing experience and use of the Site, Services, and Apps
            feel more customized. More generally, SMTHY uses Cookie Technologies
            for the following.
          </p>
          <p>
            <strong>Security and Authentication (Strictly Necessary)</strong>
          </p>
          <p>
            Some cookie and similar technology functions are necessary and vital
            to ensuring that SMTHY works properly for visitors and members, such
            as maintaining the security, safety, and integrity of the Site,
            authentication and logging into SMTHY (including remembering
            permissions and consents you have granted), and ensuring the ability
            to securely complete transactions.
          </p>
          <p>
            <strong>Account and User Preferences</strong>
          </p>
          <p>
            Some technologies are used to remember your account and preferences
            over time, such as keeping yourself logged in when returning to
            SMTHY, maintaining your choices on SMTHY features and how you want
            SMTHY to appear (including keeping track of your preferred language
            and country), and customizing content based on how you use SMTHY.
          </p>
          <p>
            <strong>Social Networks</strong>
          </p>
          <p>
            Some technologies help you to interact with social networks you are
            signed into while using the Services, such as sharing content with
            the social network, logging in with the social network, and other
            features you employ with the social network, or that are allowed in
            the social network’s privacy policy. These may be set and controlled
            by the social networks, and your preferences with those social
            networks.
          </p>
          <p>
            Social networks may also work with SMTHY or with you for analytics
            or for marketing purposes, as discussed below. You may be able to
            manage your privacy preferences for these social networks and their
            tools and widgets via your account with the social network.
          </p>
          <p>
            <strong>Performance and Analytics</strong>
          </p>
          <p>
            Some technologies help provide performance data on how the Services
            are functioning in order to improve SMTHY and the Services,
            including, for example, data on site and app functionality and speed
            to help us optimize SMTHY, how the Services are used to help us
            improve your experience on SMTHY, and detecting and gathering
            reporting on bugs to help make SMTHY work better.
          </p>
          <p>
            In addition, SMTHY may employ transient technologies, including
            cookies or local stored objects, for site performance, experiments,
            form information, and interactions with the site, and may use
            temporary, short-term cookies for limited-time site events such as
            sales and promotions.
          </p>
          <p>
            The Site employs Google Analytics to help understand how SMTHY is
            used by its community. For some of the advertising features listed
            below, like retargeting, data from Google Analytics may be combined
            with SMTHY’s first-party data and third-party cookies (like Google’s
            advertising cookies) as permitted by Google’s and SMTHY’s respective
            policies. The Site also employs Facebook App Events SDKs for
            analytics in the Apps in a similar fashion, subject to your Opt-Out.
          </p>
          <p>
            You can find more information on how Google Analytics works in this
            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
              {' '}
              Google guide{' '}
            </a>
            and on Facebook App Events{' '}
            <a href="https://developers.facebook.com/docs/app-events/">here</a>.
          </p>
          <p>
            <strong>Marketing Services</strong>
          </p>
          <p>
            SMTHY partners with third-party service providers that may use
            various Cookie Technologies to permit us and them to learn about
            which ads you see and click when you visit SMTHY, the Apps, and
            affiliated sites or to show you ads on and off SMTHY. These may
            include things such as:
          </p>
          <ul>
            <li>
              <i>Frequency capping, </i>which limits the number of times a
              user’s browser or mobile device displays the same ad;
            </li>
            <li>
              <i>Attribution tracking, </i>which estimates which advertising or
              marketing source brought someone to SMTHY, or determines which
              marketing source led to actions like a visit or a purchase;
            </li>
            <li>
              <i>Remarketing and retargeting, </i>which shows relevant ads to an
              audience based on prior shopping and browsing patterns on SMTHY;
            </li>
            <li>
              <i>Audience targeting, </i>which refers to targeting
              advertisements to a large audience based on the audience’s known
              or inferred demographics; and
            </li>
            <li>
              <i>Cross-device recognition, </i>which recognizes actions across
              multiple devices or browsers.
            </li>
          </ul>
          <p>
            Some third-party service providers may provide information like
            demographics, cross-device information, or interest categories from
            a combination of sources that, while not identifying you personally,
            permit us to provide you with more relevant and useful advertising.
            In some cases, this information may have non-marketing performance
            analytics uses as well.
          </p>
          <p>
            These technologies allow a partner to recognize your computer,
            mobile device or network device (like an IoT device such as a
            voice-activated assistant or smart TV), each time you visit SMTHY or
            other websites and mobile applications based on data like a cookie,
            your IP address, or device ID, but do not allow access to other
            personal information from SMTHY. However, these technologies may
            allow us or a third party to recognize you, either from a single
            device or across devices, over time. These third parties are
            required to follow applicable laws, self-regulatory programs, and
            SMTHY’s data protection rules where applicable. SMTHY does not have
            control over these third parties, who each have their own privacy
            policies and privacy practices.{' '}
          </p>
          <p>
            For audience targeting, SMTHY may share hashed identifiers that
            represent an email address or name with partners Google and/or
            Facebook so they can provide SMTHY with audiences interested in
            specific types of products and services. Google and/or Facebook will
            only provide this information, and can only identify you, based on
            their separate policies and agreements with their users.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          3. Services in the Apps (SDKs)
        </Typography>
        <Typography component={'span'}>
          <p>
            The Apps may include third-party application software development
            kits (“SDKs”) that provide mobile performance and analytics data,
            bug reporting features, and application program interfaces ("APIs")
            to third parties that help provide the Services, for social media
            functionality, and for marketing and advertising.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          4. Consent, Contract, and Legitimate Interests in Processing
        </Typography>
        <Typography component={'span'}>
          <p>
            Certain Cookie Technologies are employed to make the Site function
            for its intended purpose, and are provided based on contractual
            necessity based on your agreement with SMTHY to perform the services
            you have requested. These include the functions strictly necessary
            to the service noted above.
          </p>
          <p>
            By choosing to use our Services after having been notified of our
            use of Cookie Technologies in the ways described in this Policy,
            and, in applicable jurisdictions, through notice and unambiguous
            acknowledgement of your consent, you agree to such use.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>
          5. Managing Your Cookie Technology Preferences
        </Typography>
        <Typography component={'span'}>
          <p>
            You have the ability to control the use of certain Cookie
            Technologies. You can opt out of third party marketing cookies and
            similar technologies via the Privacy Settings link available at the
            bottom of most SMTHY site pages, or via the GDPR Preferences link
            for users in the European Union and EEA.
          </p>
          <p>
            <strong>Opt-in and Opt-out for Browsers</strong>
          </p>
          <p>
            In addition, when you use SMTHY via a browser, you can change your
            web browser's settings to reflect your cookie preferences. Each
            browser is a little different, but usually these settings are under
            the "options" or "preferences" menu. The links below provide
            information about cookie settings for the browsers supported by
            SMTHY:
          </p>
          <ul>
            <li>
              <a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">
                Microsoft Edge
              </a>
            </li>
            <li>
              <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
                Internet Explorer
              </a>
            </li>
            <li>
              <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">
                Firefox
              </a>
            </li>
            <li>
              <a href="https://support.google.com/chrome/answer/95647">
                Chrome
              </a>
            </li>
            <li>
              <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471">
                Safari
              </a>
            </li>
          </ul>
          <p>
            <strong>Opt-out of Third-Party Networks</strong>
          </p>
          <p>
            If you do not wish to have this information used for the purpose of
            serving you interest-based advertisements, in addition you may
            decline to receive interest based ads through TrustE/TrustArc’s
            preferences manager (or if you are located in the European Union
            click here). Please note this does not stop you from being served
            advertisements. You will continue to receive generic advertisements.
            If you reject or block all cookies in your browser settings, you
            will not be able to take advantage of SMTHY's Services as some
            cookies are necessary for the Site to function properly.
          </p>
          <p>
            You can learn more about managing your preferences for ads online,
            particularly for many third-party advertising networks, through
            resources made available by the Digital{' '}
          </p>
          <p>
            Advertising Alliance at{' '}
            <a href="https://www.aboutads.info">https://www.aboutads.info</a> or
            the Network Advertising Initiative at{' '}
            <a href="https://optout.networkadvertising.org">
              https://optout.networkadvertising.org
            </a>
            .
          </p>
          <p>
            <strong>Google Analytics Opt-out</strong>
          </p>
          <p>
            For Google Analytics Advertising Features, you can{' '}
            <a href="https://support.google.com/ads/answer/2662922?hl=en">
              opt-out
            </a>
            through{' '}
            <a href="https://adssettings.google.com/">Google Ads Settings</a>,
            Ad Settings for mobile apps, or any other available means (for
            example, the NAI's consumer opt-out listed above). Google also
            provides a Google Analytics{' '}
            <a href="https://tools.google.com/dlpage/gaoptout/">
              opt-out plug-in{' '}
            </a>
            for the web.
          </p>
          <p>
            <strong>Third-Party Tools</strong>
          </p>
          <p>
            Various third parties provide browser plug-ins and apps that can
            help provide you information on and limit or block third-party
            cookies, web beacons, and some Javascript-based technologies. SMTHY
            cannot vouch for the efficacy of a particular third-party product,
            but popular products that provide these privacy enhancements include{' '}
            <a href="https://www.ghostery.com/">Ghostery </a>and{' '}
            <a href="https://adblockplus.org/">AdBlock Plus</a>.
          </p>
          <p>
            Both Google and Facebook provide tools to control use of advertising
            on their respective platforms, including advertising on and with
            SMTHY. More information can be found in your account settings on the
            respective platforms, including in Google’s Privacy Center and in
            Facebook’s Ad Settings
          </p>
          <p>
            <strong>Mobile and Third Party Device Opt-out</strong>
          </p>
          <p>
            If you access SMTHY through the Apps, you may also control
            interest-based advertising on an iOS or Android device by selecting
            the “Limit Ad Tracking” option in the privacy section of the
            Settings App on iOS or via advertising preferences on Android-based
            devices (usually in the Google Settings app). This will not prevent
            you from seeing advertisements but will limit the use of device
            advertising identifiers to personalize ads based on your interests.
          </p>
          <p>
            For third party IoT devices such as voice activated assistants or
            smart TVs, consult with the manufacturer and/or service provider for
            the opt out mechanism for their respective devices and services.
          </p>
        </Typography>
        <Typography className={classes.subTittle}>6. Contact Us</Typography>
        <Typography component={'span'}>
          <p>
            If you have questions about the use of Cookie Technologies at SMTHY,
            please email at support@SMTHY.app{' '}
          </p>
        </Typography>
      </div>
    </Layout>
  )
}

export default TermsOfServicePage
